import Vue from 'vue'
import App from './App.vue'
import { Icon } from 'vant';
import Vant from 'vant';
import 'vant/lib/index.css';
import { Field } from 'vant';
import { Uploader } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Popup } from 'vant';
import { Badge } from 'vant';
import { Loading } from 'vant';
import { Image as VanImage } from 'vant';
import { Overlay } from 'vant';

Vue.use(Overlay);
Vue.use(VanImage);
Vue.use(Loading);
Vue.use(Badge);
Vue.use(Popup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Uploader);
Vue.config.productionTip = false
Vue.use(Icon);
Vue.use(Field);
new Vue({
  render: h => h(App),
}).$mount('#app')
