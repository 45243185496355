<template>
  <div id="app">
    <div class="top">
      <img
        class="textimg"
        src="@/assets/37f266332fd22a547add557621dc8b8f.png"
        alt=""
      />

      <div class="text text1">
        真人律师回复，另赠 <span class="theme_color">2</span> 次追问
      </div>
      <div class="text text2">
        已有 <span class="theme_color">{{ paid_people }}</span> 咨询
      </div>

      <img
        class="bgimg"
        src="@/assets/eed405d3d97c4597ca93c2d3c1996e5f.png"
        alt=""
      />
    </div>

    <div class="main">
      <div class="title">
        <div class="main_title">问题描述</div>
        <div class="sub_title" @click="show_desc_example = !show_desc_example">
          如何描述?
        </div>
      </div>
      <div class="desc_example" v-if="show_desc_example">
        {{ desc_example }}
      </div>

      <div class="youdian">
        <div class="youdian_item">
          <van-icon class="icon" name="contact" />
          <div class="text">实名认证</div>
        </div>
        <div class="youdian_item">
          <van-icon class="icon" name="manager-o" />
          <div class="text">真实律师</div>
        </div>
        <div class="youdian_item">
          <van-icon class="icon" name="search" />
          <div class="text">严审律师资格</div>
        </div>
        <!-- <div class="youdian_item">
          <van-icon class="icon" name="after-sale" />
          <div class="text">未服务退款</div>
        </div> -->
      </div>
      <div class="form">
        <textarea
          class="content"
          placeholder="请描述下您要咨询的问题：1.问题发生的时间及背景 2.目前的情况 3.您的疑问或诉求"
          rows="6"
          v-model="content"
        ></textarea>
        <div class="word_num">
          <div class="limited">{{ limited }}</div>
          /
          <div class="enter">{{ enter }}</div>
        </div>

        <div class="type_zone">
          <div class="left" @click="show_type = true">
            <div class="text">
              {{ selected_type == "" ? "请选择问题类型" : selected_type }}
            </div>
            <van-icon class="icon" name="arrow-down" />
          </div>
          |
          <div class="right" @click="show_zone = true">
            <div class="text">
              {{ selected_zone == "" ? "请选择地区" : selected_zone }}
            </div>
            <van-icon class="icon" name="arrow-down" />
          </div>
        </div>

        <van-field
          class="phone_input"
          :maxlength="11"
          v-model="phone"
          type="number"
          label="手机号:"
          placeholder="方便我们与您取得联系"
        />

        <!-- 为了简化逻辑，禁用一次选择多张图片 -->
        <van-uploader
          v-if="!miniprogram"
          class="uploader"
          upload-text="添加图片"
          :before-read="beforeRead"
          v-model="fileList"
          :max-size="1024 * 1024 * 8"
          :max-count="max_count"
        />

        <!-- 自定义实现 -->
        <!-- 没有上传中的状态，交给支付宝的接口去表现上传状态 -->
        <!-- 只显示已上传成功的图片 -->
        <div class="custom_uploader" v-else>
          <div class="uploader_item" :class="item.status" v-for="(item, index) in fileList" :key="index">
            <van-icon @click="remove_img_item(index)" class="icon close_btn" v-if="item.status != 'uploading'" name="cross" />
            <van-loading class="icon uploading" v-if="item.status == 'uploading'" />
            <van-icon class="icon upload_fail" v-if="item.status == 'failed'" name="photo-fail" />
            <van-image @click="preview_img(index)" class="img_cover"  v-if="item.status == 'done'" fit="cover" :src="item.url" />
            <div>{{item.message}}</div>
          </div>
          

          <div class="add_btn uploader_item" v-show="show_add_btn" @click.stop="select_upload_images">
            <van-icon class="icon" name="photograph" />
            <div class="text">添加图片</div>
          </div>
        </div>
      </div>

      <div class="upload_btn" @click="submit">一键咨询</div>

      <van-swipe
        class="swipe"
        vertical
        :show-indicators="false"
        autoplay="2000"
        loop
      >
        <van-swipe-item
          class="fake_roll"
          v-for="(item, index) in fake_data"
          :key="index"
        >
          <img class="icon" src="@/assets/avatar.png" alt="" />
          <div class="text">
            {{ item.city }}用户提交咨询后，<span class="theme_color">{{
              item.min
            }}</span
            >分钟内获得了<span class="theme_color">{{ item.count }}</span
            >位律师的回复
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <van-popup
      v-model="show_type"
      position="bottom"
      closeable
      close-icon="close"
      :style="{ height: '40%' }"
    >
      <div class="popup type">
        <div class="header">选择问题类型</div>
        <div
          class="item type"
          v-for="(item, index) in types"
          :key="index"
          @click="select_type"
        >
          {{ item }}
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="show_zone"
      position="bottom"
      closeable
      close-icon="close"
      :style="{ height: '40%' }"
    >
      <div class="popup zone">
        <div class="header">请选择区域（仅限广东地区）</div>

        <div
          class="item zone"
          v-for="(item, index) in city"
          :key="index"
          @click="select_zone"
        >
          {{ item }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import axios from "axios";
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  name: "App",
  data() {
    return {
      max_count: 8,
      types: [
        "婚姻家庭",
        "交通事故",
        "医疗纠纷",
        "劳动争议",
        "债权债务",
        "刑事辩护",
        "合同纠纷",
        "公司法律",
        "知识产权",
        "侵权维权",
        "经济金融",
        "行政纠纷",
        "房产纠纷",
        "拆迁安置",
        "工程建筑",
        "其他",
      ],
      city: [
        "广州市",
        "韶关市",
        "深圳市",
        "珠海市",
        "汕头市",
        "佛山市",
        "江门市",
        "湛江市",
        "茂名市",
        "肇庆市",
        "惠州市",
        "梅州市",
        "汕尾市",
        "河源市",
        "阳江市",
        "清远市",
        "东莞市",
        "中山市",
        "潮州市",
        "揭阳市",
        "云浮市",
      ],

      selected_type: "",
      selected_zone: "",

      show_type: false,
      show_zone: false,
      fake_data: [],
      paid_people: Math.floor(new Date().valueOf() / 1000) % 1000000,
      show_desc_example: false,
      desc_examples: [
        "例：身份证丢失过遭到冒用，收到催款电话以为是诈骗，结果接到好几次，查征信才知道名下有两笔贷款，请问该如何解决？可以起诉银行吗？",
        "例：邻居家盖的房子超出了宅基地的边界线半米，占用了一部分过道，我该怎么维权？",
        "例：在公司工作了两年，在职期间没有签订劳动合同，也没买社保，现在无理由解雇我，直接让我不用去上班了，这样的情况能去劳动仲裁吗？能要到什么赔偿？需要准备什么材料？",
        "例：结婚8年老公出轨，想离婚他不愿意，两个孩子一个六岁一个四岁，家里有一套房一辆车几万块共同存款，房子是他婚前贷款买的，车是我们婚后买的，起诉离婚孩子会怎么判？房车存款会怎么分割？",
        "例：被车撞伤，对方肇事逃逸，交警认定对方全责，但他态度很不好，不垫付医药费，也不报保险，找他协商也不搭理，我该如何处理？",
        "例：朋友前年找我借了十万元一直没还，我多次讨要都被他用各种无理借口推辞，我们距离很远又没法找到他家里索要，我在山东他是广东人。当时也没打借条但是有转账记录和聊天记录，这种情况该怎么办？",
      ],
      content: "",
      limited: 300,
      phone: "",
      // 通过 status 属性可以标识上传状态，uploading 表示上传中，failed 表示上传失败，done 表示上传完成。
      fileList: [
        // {
        //   url: 'https://img01.yzcdn.cn/vant/leaf.jpg',
        //   status: 'uploading',
        //   message: '上传中...',
        //   isImage: true,
        // },
        // {
        //   url: 'https://img01.yzcdn.cn/vant/leaf.jpg',
        //   status: 'failed',
        //   message: '上传失败...',
        //   isImage: true,
        // },
        // {
        //   url: 'https://img01.yzcdn.cn/vant/leaf.jpg',
        //   status: 'done',
        //   message: '',
        //   isImage: true,
        // },
      ],
      max_size: 2014 * 1024 * 8, // 8MB
      form_data: {
        key: "",
        policy: "",
        OSSAccessKeyId: "LTAI4FoJXe1v9PHn1t3KBoTX",
        success_action_status: 200,
        callback: "",
        signature: "",
      }, // oss上传参数
      oss_host: "https://car20210420.oss-cn-guangzhou.aliyuncs.com/",
      miniprogram: false,
    };
  },
  computed: {
    show_add_btn() {
      return this.fileList.length < this.max_count ? true : false;
    },
    desc_example() {
      const length = this.desc_examples.length;
      return this.desc_examples[Math.floor(Math.random() * length)];
    },
    enter() {
      return this.content.trim().length;
    },
  },
  created() {
    if (window.my) {
      my.getEnv((res) => {
        this.miniprogram = res.miniprogram;

        if (this.miniprogram) {
          my.onMessage = (e) => {
            const { type } = e
            if (type == 'uploadImagesRes') {
              const { backend_response } = e

              if (backend_response.status == 0) {
                const { fileinfo } = backend_response
                const { filename } = fileinfo
                const fullimguri = this.oss_host + filename
                
              
                let new_img_item = {
                  url: fullimguri,
                  status: 'done',
                  message: '',
                  isImage: true,
                }

                this.fileList.push(new_img_item)  

                while (this.fileList.length > this.max_count)
                  this.fileList.shift()


              }
            }
          };
        }
      });
    }


    document.documentElement.style.fontSize =
      (document.documentElement.clientWidth / 375) * 16 + "px";

    window.addEventListener("resize", () => {
      document.documentElement.style.fontSize =
        (document.documentElement.clientWidth / 375) * 16 + "px";
    });

    let fake_data = [];

    for (let i = 0; i < 20; i++) {
      let item = {
        city: this.city[Math.floor(Math.random() * this.city.length)],
        min: Math.floor(Math.random() * 5) + 3,
        count: Math.floor(Math.random() * 5) + 3,
      };

      fake_data.push(item);
    }

    this.fake_data = fake_data;
  },
  methods: {
    preview_img(index) {
      my.previewImage({
        current: index,
        urls: this.fileList.map(e => e.url),
      })
    },
    remove_img_item(index) {
      this.fileList.splice(index, 1)
    },
    select_upload_images() {
      my.postMessage({
        type: 'uploadImages',
        oss_host: this.oss_host,
        left_count: this.max_count - this.fileList.length,
      })
    },
    click_upload(e) {
      e.preventDefault();
      e.stopPropagation();
      console.log(e)
    },
    submit() {
      const { selected_type, selected_zone, content, phone, fileList } = this;
      let file_list = fileList.map((e) => e.url);

      // console.log(selected_type);
      // console.log(selected_zone);
      // console.log(content);
      // console.log(phone);
      // console.log(file_list);

      if (content.trim().length < 10 || 300 < content.trim().length) {
        Toast(
          "请详细描述问题经过，以便精准获得律师解答！内容长度为10-300个字符！"
        );
        return;
      }

      if (selected_type == "") {
        Toast("请选择问题类型");
        return;
      }

      if (selected_zone == "") {
        Toast("请选择地区");
        return;
      }

      if (phone == "") {
        Toast("请输入联系电话");
        return;
      }

      if (!/1[3-9][0-9]{9}/.test(phone)) {
        Toast("联系电话格式不正确");
        return;
      }

      let data = {
        content,
        selected_type,
        selected_zone,
        phone,
        file_list,
      };

      axios
        .post("https://backend.chechakj.com/api/lawyer/submit_form", data)
        .then((res) => {
          let result = res.data;
          if (result.status == 0) {
            Dialog.alert({
              title: "提交成功",
              message: "我们的律师团队将尽快为您处理！",
            }).then(() => {});
            return;
          }
        });
    },
    select_type(event) {
      this.selected_type = event.target.textContent;
      console.log(this.selected_type);

      this.show_type = false;
    },
    select_zone(event) {
      this.selected_zone = event.target.textContent;
      console.log(this.selected_zone);
      this.show_zone = false;
    },

    zfb_img_upload() {
      if (this.miniprogram) {
        my.postMessage({
          type: "RequestUploadImage",
          params: {},
        });

        return;
      }
    },

    beforeRead(file) {
      let is_array = Array.isArray(file);
      // console.log(is_array)    // 永远是 false, 已默认禁用同时选择多张

      const { size } = file;

      if (size > this.max_size) {
        Toast.fail("请选择小于8MB的图片");
        return;
      }

      this.get_upload_params().then(res => {
          // 上传参数已获取

          let new_img = {
            // url: 'https://car20210420.oss-cn-guangzhou.aliyuncs.com/' + this.form_data.key,
            status: "uploading",
            message: "上传中...",
            isImage: true,
          };

          this.fileList.push(new_img);

          let param = new FormData();

          for (const key in this.form_data)
            param.append(key, this.form_data[key]);

          param.append("file", file); // file 字段需要放到后面，不然阿里云报400

          let config = { headers: { "Content-Type": "multipart/form-data" }, };

          axios.post(this.oss_host, param, config).then((res) => {
            let result = res.data;

            if (result.status != 0) {
              Toast.fail("抱歉，该图片上传失败了");
              new_img.status = "failed";
              new_img.message = "上传失败";

              return;
            }
            new_img.url = this.oss_host + result.fileinfo.filename;
            new_img.status = "done";
          });
      })
    },

    get_upload_params() {
      let sign_policy_url = "https://backend.chechakj.com/api/lawyer/sign_policy";
      return  axios.get(sign_policy_url).then((res) => {
          if (res.status != 200) {
            Toast.fail("系统错误，请联系客服！");
            return false;
          }

          let { policy, signature, key, callback } = res.data;
          this.form_data.policy = policy;
          this.form_data.signature = signature;
          this.form_data.key = key + ".jpg";
          this.form_data.callback = callback;
        })
    }
  },
  components: {},
};
</script>

<style lang="less">
html {
  font-size: 16px;
  background-color: #fff;
  padding: 0;
  margin: 0;

  .theme_color {
    color: #5177f4;
  }
}

body {
  padding: 0;
  margin: 0;

  .top {
    width: 23.4375rem;
    height: 9.0625rem;
    background-color: #d6e4fe;
    position: relative;
    overflow: hidden;

    .textimg {
      position: absolute;
      display: block;
      width: 16.125rem;
      height: 3.8125rem;
      top: 0.9375rem;
      left: 0.9375rem;
    }

    .bgimg {
      position: absolute;
      display: block;
      width: 7.5rem;
      height: 7.5rem;
      bottom: 0;
      right: -1.25rem;
    }

    .text {
      position: absolute;
      left: 0.9375rem;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      opacity: 0.8;
      color: #333;
    }

    .text1 {
      top: 5rem;
    }

    .text2 {
      top: 6.25rem;
    }
  }

  .main {
    padding: 1rem 0.9375rem;
    background-color: #fff;
    border-radius: 0.9375rem 0.9375rem 0 0;
    position: relative;
    top: -0.9375rem;

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .main_title {
        font-weight: bold;
        font-size: 1.0625rem;
        color: #333;
      }

      .sub_title {
        font-size: 0.8125rem;
        line-height: 0.8125rem;
        margin-left: 0.375rem;
        color: #5377f0;
        position: relative;
        top: 0.125rem;
      }
    }

    .desc_example {
      background-color: #eaf1fe;
      color: #555;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: 0.375rem 0.75rem;
      border: 0.0625rem solid #baccfa;
      border-radius: 0.25rem;
      margin-top: 0.9375rem;
    }

    .youdian {
      display: flex;
      color: #b2b2b2;
      font-size: 0.75rem;
      line-height: 0.75rem;
      margin-top: 0.9375rem;

      .youdian_item {
        display: flex;
        margin-right: 0.625rem;
        justify-content: center;
        align-items: center;

        .icon {
          margin-right: 0.125rem;
          width: 0.75rem;
          height: 0.75rem;
          display: block;
        }
      }
    }

    .form {
      width: calc(100% - 1.5rem);
      padding: 0.75rem;
      margin-top: 0.8125rem;
      border-radius: 0.3125rem;
      background-color: #f5f5f5;

      .content {
        width: 100%;
        padding: 0;
        border: none;
        background-color: #f5f5f5;
        font-size: 0.875rem;
        line-height: 1.575rem;
        outline: none;
        color: #000;
        font-weight: 400;
      }

      .word_num {
        color: #888;
        font-size: 0.9375rem;
        display: flex;
        direction: rtl;
      }

      .type_zone {
        color: gray;

        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          cursor: pointer;
          width: 40%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .text {
          font-size: 0.875rem;
          line-height: 1.9375rem;
          color: #111;
        }

        .icon {
          font-size: 1.125rem;
          color: gray;
          position: relative;
          bottom: 0.125rem;
        }
      }

      .phone_input {
        background-color: #f5f5f5;

        &.van-cell.van-field {
          padding-left: 0;

          .van-field__label {
            color: #111;
            width: 3.125rem;
          }
        }
      }

      .uploader {
        margin-top: 0.625rem;

        .van-uploader__wrapper {
          display: flex;
          justify-content: space-between;
        }

        .van-image.van-uploader__preview-image,
        .van-uploader__upload {
          width: 4.375rem;
          height: 4.375rem;
        }

        .van-uploader__preview:nth-child(4n) {
          margin-right: 0;
        }
      }

      .custom_uploader {
        width: 20.0625rem;
        height: 9.8125rem;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;

        justify-content: flex-start;
        align-content: space-between;

        .uploader_item {
          &.uploading, &.failed {
            background-color: rgba(50,50,51,.88);
          }
          margin-right: 0.4375rem;
          width: 4.6875rem;
          height: 4.6875rem;
          display: block;
          box-sizing: border-box;
          // border: .0625rem solid #888;
          box-shadow: 0 0 0.625rem #ddd;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;

          .img_cover {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            display: block;
          }
          

          .close_btn {
            cursor: pointer;
            font-size: .75rem !important;
            line-height: 3.5rem;
            text-indent: -0.5rem;
            position: absolute;
            z-index: 2;
            top: -1.25rem;
            right: -1.25rem;
            background-color: rgba(0,0,0,.7);
            width: 2.5rem;
            height: 2.5rem;
            color: white;
            border-radius: 50%;

            
          }

          &:nth-child(4n) {
            margin-right: 0;
          }
        }

        .uploader_item {
          cursor: pointer;
          font-size: 0.75rem;
          color: #969799;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .icon {
            color: #dcdee0;
            font-size: 1.5rem;
          }
          .text {
            margin-top: 0.5rem;
          }
        }
      }
    }

    .upload_btn {
      width: 21.5625rem;
      height: 3.125rem;
      cursor: pointer;
      border-radius: 3.125rem;
      line-height: 3.125rem;
      color: white;
      background: linear-gradient(90deg, #5d99fb 0, #688bfe 46%, #727af6 100%);
      margin-top: 1.75rem;
      text-align: center;
      font-size: 1.125rem;
    }

    .swipe {
      height: 1.875rem;
      margin: 1.25rem 0;

      .fake_roll {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          width: 0.8125rem;
          height: 0.8125rem;
          border-radius: 50%;
          margin-right: 0.1875rem;
          display: block;
        }

        .text {
          font-size: 0.8125rem;
          line-height: 0.8125rem;
          color: #999;
        }
      }
    }
  }

  .popup {
    height: 100%;
    position: relative;
    font-size: 1rem;
    color: #666;
    font-size: 0.875rem;
    overflow-y: scroll;
    overflow-x: hidden;

    .header {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      color: #333;
      font-size: 0.9375rem;
      height: 3.125rem;
      background-color: #f5f5f5;
      line-height: 3.125rem;
      padding-left: 0.9375rem;
      font-weight: 400;
    }

    .zhanwei {
      width: 100%;
      height: 3.125rem;
      display: block;
    }

    .item {
      padding-left: 0.9375rem;
      height: 2.8125rem;
      border-top: 0.0625rem solid #eee;
      line-height: 2.8125rem;
    }
  }
}
</style>
